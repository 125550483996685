import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { SearchIcon } from '../components/SearchIcon';
import { Index } from 'elasticlunr';
import SEO from '../components/SEO';
import SearchResultsList from '../components/SearchResultsList';
import Layout from '../components/Layout';
import './search.scss';

const INITIAL_POSTS_TO_SHOW = 12;
const POST_INCREMENTOR = 12;
const isClient = typeof window !== 'undefined';
class Search extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const queryStringArray = location.search.substring(1).split('&');
    const origin = queryStringArray.length > 1 ? queryStringArray[1].split('=')[1] : null;
    this.state = {
      searchVal: '',
      filteredMatchingPosts: [],
      matchingPosts: [], // original search results
      postsToShow: INITIAL_POSTS_TO_SHOW,
      activeFilter: 0,
      origin,
    }
  }

  componentDidMount() {
    const { location } = this.props;
    // Get queryString
    const { search: queryString } = location;
    this.handleSearch(queryString);
  }

  componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location: newLocation } = nextProps;
    // Get queryString
    const { search: oldQueryString } = oldLocation;
    const { search: newQueryString } = newLocation;
    // Get origin
    const queryStringArray = newLocation.search.substring(1).split('&');
    const origin = queryStringArray.length > 1 ? queryStringArray[1].split('=')[1] : null;
    this.setState({
      origin
    }, () => {
      if (oldQueryString !== newQueryString) this.handleSearch(newQueryString);
    })
  }

  handleSearch = (queryString) => {
    const queryStringArray = queryString.substring(1).split('&');
    const searchVal = queryStringArray[0].split('=')[1];
    const cleanedSearchQuery = searchVal ? decodeURIComponent(searchVal) : '';
    this.setState({
      searchVal: cleanedSearchQuery,
      originalSearchQuery: cleanedSearchQuery
    });
    if (cleanedSearchQuery) {
      this.search(null, cleanedSearchQuery);
    }
  }

  showMorePosts = (event) => {
    event.preventDefault();
    const { postsToShow } = this.state;
    this.setState({ postsToShow: postsToShow + POST_INCREMENTOR });
  }

  getOrCreateIndex = () => {
    const {
      data: {
        siteSearchIndex
      }
    } = this.props;

    return this.index ?
      this.index :
      // Create an elastic lunr index and hydrate with graphql query results
      Index.load(siteSearchIndex.index);
  }

  filterItems = (filterIndex, filterName) => {
    const { matchingPosts } = this.state;
    const filteredMatchingPosts = filterName ? matchingPosts.filter((item) => {
      return item.type === filterName;
    }) : matchingPosts;
    this.setState({
      filteredMatchingPosts,
      activeFilter: filterIndex
    });
  }

  search = (event, value) => {
    const { origin } = this.state;
    const { data: { categories, allPages, allResourceTypes } } = this.props;
    const searchVal = value || event.target.value;
    this.index = this.getOrCreateIndex();
    let results = this.index.search(searchVal, {})
      // Map over each ID and return the full document
      .map(({
        ref,
      }) => {
        const { documentStore } = this.index;
        const result = documentStore.getDoc(ref);
        if (result.type.constructor === Array) {
          const resourceType = allResourceTypes.edges.find(edge => edge.node.wordpress_id === result.type[0]);
          result.type = resourceType.node.slug;
        }
        const categoryNodes = result.categoryRefs ? result.categoryRefs.map(catRef => (
          categories.edges.find(edge => edge.node.id === catRef)
        )) : [];
        const parentPageNode = result.parentRef ?
          allPages.edges.find(edge => edge.node.id === result.parentRef) :
          null;
        result.type = result.type.split('-').map(el => el.charAt(0).toUpperCase() + el.substring(1)).join(' ');
        return { ...result, categories: categoryNodes, parentPage: parentPageNode };
      });
    // If origin is the knowledge hub, filter results using result type (see gatsby-config.js)
    if (origin === 'hub') {
      results = results.filter(item => {
        return item.type === 'Whitepapers' || item.type === 'Article' || item.type === 'Videos' || item.type === 'Brochures';
      })
    }
    this.setState({
      searchVal,
      // Query the index with search string to get an [] of IDs
      matchingPosts: results,
      filteredMatchingPosts: results,
    });
  }

  scrollIntoView = (event, containerId) => {
    event.preventDefault();
    if (isClient) {
      const results = document.getElementById(containerId);
    }else{
      const results = '';
    }
    results.scrollIntoView({ block: 'start',  behavior: 'smooth' });
  }

  render() {
    const {
      searchVal,
      // originalSearchQuery,
      filteredMatchingPosts,
      matchingPosts,
      postsToShow,
      activeFilter,
      origin
    } = this.state;
    const visiblePosts = filteredMatchingPosts.slice(0, postsToShow);
    return (
      <Layout topNavTheme="white">
        <SEO title={`${searchVal ? `Search results for: ${searchVal}` : 'Search Results'}`} />
        <section className="search-page">
          <div className="header search-input">
            <div className="form-inner">
              <div className={`${searchVal ? ' active' : ''}`}>
                <label htmlFor="search">Enter search phrase here...</label>
                <input name="search" type="text" placeholder="Enter search phrase here..." onChange={e => this.search(e)} value={searchVal} />
                {searchVal && (
                  <button type="submit" aria-label="Search" className={searchVal ? 'active' : 'not-active'}>
                    <SearchIcon />
                  </button>
                )}
              </div>

              <div className="result-text">
              { (filteredMatchingPosts && filteredMatchingPosts.length) ? (
                <span className="result-count">We found {filteredMatchingPosts.length} result{filteredMatchingPosts.length > 1 ? 's' : ''} that matched your query</span>
              ) : (
                <span className="result-count">No Results found</span>
              )}
              </div>

            </div>
          </div>

          <SearchResultsList
            visibleItems={visiblePosts}
            allMatchingItems={matchingPosts}
            totalItems={filteredMatchingPosts ? filteredMatchingPosts.length : 0}
            searchQuery={searchVal}
            showMorePostsHandler={this.showMorePosts}
            origin={origin}
            filterItems={this.filterItems}
            activeFilter={activeFilter}
          />
        </section>
      </Layout>
    );
  }
}

// Graphql query used to retrieve the serialized search index.
export default (props) => (
  <StaticQuery
    query={graphql`
      query SearchPageSearchIndexQuery {
        siteSearchIndex {
          index
        }
        categories: allWordpressCategory {
          edges {
            node {
              id
              slug
              name
            }
          }
        }
        allPages: allWordpressPage {
          edges {
            node {
              id
              slug
            }
          }
        }
      }
    `}
    render={data => <Search data={data} {...props} />}
  />
)
